import React from "react";
import {
  MDBFooter,
  MDBContainer,
  MDBIcon,
  MDBInput,
  MDBCol,
  MDBRow,
  MDBBtn,
} from "mdb-react-ui-kit";

export function Footer() {
  return (
    <div className="text-center ">
      <div className="text-center p-3 d-flex justify-content-center" style={{ backgroundColor: "#9FCECE" }}>
        <p className="text-white ms-2 margin-align nunito">© 2022 </p>
        <p className="text-white ms-2 margin-align nunito">OLIMAR BUESO</p>
        <a className="a-link-footer me-2 icon" href="https://www.instagram.com/serytrascender" target="_blank"><i class="fab fa-instagram mt-1 ms-2"></i></a>
        <a className="a-link-footer me-2 icon " href="https://www.linkedin.com/in/olimarbueso" target="_blank"><i class="fab fa-linkedin mt-1 ms-2"></i></a>
        <a className="a-link-footer me-2 icon" href="https://facebook.com/psicolimarbueso/" target="_blank"><i class="fab fa-facebook-square mt-1 ms-2"></i></a>
        <a className="a-link-footer me-2 icon" href="https://www.youtube.com/channel/UC_3okv7OxwQoXXzxXnLJiRQ" target="_blank"><i class="fab fa-youtube"></i></a>
        <a className="a-link-footer me-2 icon" href="https://facebook.com/psicolimarbueso/" target="_blank"><i class="fab fa-spotify"></i></a>
      </div>
    </div>
  );
}
