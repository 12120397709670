import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import icon1 from "../img/interaction.png";
import { Footer } from "../components.js/footer";
import icon2 from "../img/social-marketing.png";
import icon3 from "../img/therapy.png";
import icon4 from "../img/mental-health.png";
import "../styles/about.css";
import foto5 from "../img/colaboraciones.jpg";
import foto6 from "../img/Terapia-Individual.jpg";
import foto7 from "../img/Counseling.png";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBContainer,
  MDBInputGroup,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { QuoteParallaxTeam } from "../components.js/quoteParallaxTeam";
import { ParallaxColaboraciones } from "../components.js/parallaxColaboraciones";

export const AboutUs = () => {
  const getWindowDimensions = () => {
    const { innerWidth: width } = window;
    return {
      width,
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  let settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // nextArrow: true,
    // prevArrow: true
  };
  let settings2 = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // nextArrow: true,
    // prevArrow: true
  };

  return (
    <>
      {/* <h1 className="text-center">working</h1>
<h1 className="text-center">working</h1>
<h1 className="text-center">working</h1>
<h1 className="text-center">working</h1>
<h1 className="text-center">Almost done</h1> */}

      {/* <div className="about mt-2">
            <h1 className="text-center py-5">COLABORATION</h1>
        </div> */}

      <ParallaxColaboraciones />

      <Slider
        {...(windowDimensions.width > 800 ? settings : settings2)}
        className="test mt-4"
      >
        <div className={windowDimensions.width > 800 ? "d-flex" : "d-blcok"}>
          <img
            src="https://images.pexels.com/photos/3810788/pexels-photo-3810788.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            className="carrousel-image"
          />
          <div className="m-auto text-center">
            <h1 className="m-auto padding-controller Licorice banner-2-h2">
              Colaboraciones y capacitaciones
            </h1>
            <p className="m-auto px-4 bg-text-1 padding-controller-2 nunito">
              ¿Deseas fortalecer la gestión emocional, estrés, comunicación
              asertiva u otro aspecto en tu organización? Te ayudamos con
              capacitaciones, talleres y entrenamientos online o terapias
              grupales
            </p>
            <div className="arrows-body ">
              <div className="arrrow-container no-pc">
                <div className="arrow"></div>
                <div className="arrow"></div>
                <div className="arrow"></div>
              </div>
            </div>
          </div>
        </div>
        <div className={windowDimensions.width > 800 ? "d-flex" : "d-blcok"}>
          <img
            src={foto5}
            className="carrousel-image"
          />
          <div className="m-auto text-center">
            <h1 className="m-auto padding-controller Licorice banner-2-h2">
              Colaboraciones
            </h1>
            <p className="m-auto px-4 bg-text-1 padding-controller-2 nunito">
              ¿Necesitas algún servicio psicológico en tu organización? Algunas
              organizaciones con las que Olimar ha colaborado.
            </p>
            <div className="arrows-body ">
              <div className="arrrow-container no-pc">
                <div className="arrow"></div>
                <div className="arrow"></div>
                <div className="arrow"></div>
              </div>
            </div>
          </div>
        </div>
        <div className={windowDimensions.width > 800 ? "d-flex" : "d-blcok"}>
          <img
            src="https://images.pexels.com/photos/7176319/pexels-photo-7176319.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            className="carrousel-image"
          />
          <div className="m-auto text-center">
            <h1 className="m-auto padding-controller Licorice banner-2-h2">
              Salud y Psiconeuroinmunologia
            </h1>
            <p className="m-auto px-4 bg-text-1 padding-controller-2 nunito">
              ¿Eres profesional de la salud y necesitas acompañamiento
              psicológico para tus pacientes?
            </p>
            <p className="m-auto px-4 bg-text-1 padding-controller-2 nunito">
              ¿Notas que sus síntomas físicos o enfermedad se intensifican por
              estrés?
            </p>
            <p className="m-auto px-4 bg-text-1 padding-controller-2 nunito">
              Realizamos un acompañamiento integrativo y nos encanta trabajar en
              comunicación con el equipo multidisciplinario.
            </p>
            <div className="arrows-body ">
              <div className="arrrow-container ">
                <div className="arrow"></div>
                <div className="arrow"></div>
                <div className="arrow"></div>
              </div>
            </div>
          </div>
        </div>
        <div className={windowDimensions.width > 800 ? "d-flex" : "d-blcok"}>
          <img
            src={foto6}
            className="carrousel-image"
          />
          <div className="m-auto text-center">
            <h1 className="m-auto  padding-controller Licorice banner-2-h2">
              Evaluación psicológica pre quirúrgica
            </h1>
            <p className="m-auto px-4 bg-text-1 padding-controller-2 nunito">
              ¿Necesitas una valoración psicológica prequirúrgica o bariatrica?
              Te acompañamos en un tratamiento integral.
            </p>
            <div className="arrows-body ">
              <div className="arrrow-container">
                <div className="arrow"></div>
                <div className="arrow"></div>
                <div className="arrow"></div>
              </div>
            </div>
          </div>
        </div>
        <div className={windowDimensions.width > 800 ? "d-flex" : "d-blcok"}>
          <img
            src={foto7}
            className="carrousel-image"
          />
          <div className="m-auto text-center">
            <h1 className="m-auto  padding-controller Licorice banner-2-h2">
              Cursos, conferencias y clases pregrabadas
            </h1>
            <p className="m-auto px-4 bg-text-1 padding-controller-2 nunito">
              Adquiere las clases y actividades online pregrabadas y disfruta en
              diferido las conferencias en vivo.
            </p>
            <div className="arrows-body ">
              <div className="arrrow-container ">
                <div className="arrow"></div>
                <div className="arrow"></div>
                <div className="arrow"></div>
              </div>
            </div>
          </div>
        </div>
        <div className={windowDimensions.width > 800 ? "d-flex" : "d-blcok"}>
          <img
            src="https://images.pexels.com/photos/4143791/pexels-photo-4143791.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
            className="carrousel-image"
          />
          <div className="m-auto text-center">
            <h1 className="m-auto padding-controller Licorice banner-2-h2">
              Recursos Terapéuticos
            </h1>
            <p className="m-auto px-4 bg-text-1 padding-controller-2 nunito">
              Para la familia, niños, adolescentes y adultos aprendan a
              gestionar sus emociones, mejorar confianza y valía personal.
              Consulta nuestros recursos gratuitos en las redes sociales
            </p>
            <div className="arrows-body ">
              <div className="arrrow-container">
                <div className="arrow"></div>
                <div className="arrow"></div>
                <div className="arrow"></div>
              </div>
            </div>
          </div>
        </div>
      </Slider>
      <div className="test22"></div>
      <div className="homepage-banner-4 banner-padding">
        <h1 className="banner-2-h1 px-1 text-center text-black pb-5 mb-3 Licorice">
          Salud mental en cifras
        </h1>
        <MDBRow className="orientation-flex">
          <MDBCol
            className="border-decoration text-center nunito"
            lg="4"
            md="4"
            sm="12"
          >
            <h1>1 de 4</h1>
            <p className="p-3">
              personas, tendrá un trastorno mental a lo largo de su vida según
              la OMS.
            </p>
          </MDBCol>
          <div className=" stack-border">
            <div className="border-responsive"></div>
          </div>
          <MDBCol
            className="separation-border text-center nunito"
            lg="4"
            md="4"
            sm="12"
          >
            <h1>1 de 6</h1>
            <p className="p-3">
              jóvenes estadounidenses de 6 a 17 años experimenta un trastorno de
              salud mental cada año (NAMI, 2022).
            </p>
          </MDBCol>
          <div className=" stack-border">
            <div className="border-responsive stack-border"></div>
          </div>
          <MDBCol
            className="border-decoration-left text-center nunito"
            lg="4"
            md="4"
            sm="12"
          >
            <h1>Para el 2030</h1>
            <p className="p-3">
              Las enfermedades de salud mental podrían ser la causa principal de
              discapacidad en el mundo (OMS).
            </p>
          </MDBCol>
        </MDBRow>
        <h3 className="pt-5 m-0 text-center fs-32 text-al mobile-view-hidden text-black nunito">
          En la prevención está la clave <br /> ¡No estás sol@, PIDE AYUDA!
        </h3>
      </div>
      <QuoteParallaxTeam />
      <div className="mygoals">
        <div>
          <h1 className="text-center Licorice text-black banner-2-h1  pt-5 ">
            Enfoque Integrativo
          </h1>
        </div>
        <div className="d-flex flex-wrap justify-content-center separation-padding-mobile">
          <div className="goalsBox ">
            <div className="child">
              <img src={icon4} className="icon-class-image" />
              <h4 className="m-auto p-3 nunito">Ciencia y Psicología</h4>
            </div>
          </div>
          <div className="goalsBox ms-3">
            <div className="child">
              <img src={icon1} className="icon-class-image" />
              <h4 className="m-auto p-3 nunito">Enfoque multidimensional</h4>
            </div>
          </div>
          <div className="goalsBox ms-3">
            <div className="child">
              <img src={icon3} className="icon-class-image" />
              <h4 className="m-auto p-3 nunito">
                Auto conocimiento y sentido de vida
              </h4>
            </div>
          </div>
          <div className="goalsBox ms-3">
            <div className="child">
              <img src={icon2} className="icon-class-image" />
              <h4 className="m-auto p-3 nunito">
                Contenido psicoeducativo para mejorar salud mental.
              </h4>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
