import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import "../styles/contact.css";
import emailjs from "@emailjs/browser";
import { Footer } from "../components.js/footer"; 
import { PopupWidget } from "react-calendly";
import foto8 from "../img/IMG_1084.JPG";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardImage,
  MDBBtn,
  MDBRipple,
} from "mdb-react-ui-kit";
import { InlineWidget } from "react-calendly";
import { Background } from "react-parallax";
import { QuoteParallaxContactMe } from "../components.js/parrallaxContactMe";
import { QuoteParallaxContactMeTwo } from "../components.js/parrallaxContactMeTwo";
import { PopupButton } from "react-calendly";

export const ContactMe = () => {

  const getWindowDimensions = () => {
    const { innerWidth: width } = window;
    return {
      width,
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });
  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_h5r48pw",
        "template_ebw8oqv",
        form.current,
        "60qBabnPOG5Q3WCnb"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    form.current.reset();
  };

  return (
    <>
    <div>
      <QuoteParallaxContactMe />
      <div className="containerContact">
        {/* <div class="background">
          <div class="container">
            <div class="screen">
              <div class="screen-header">
                <div class="screen-header-left2">
                  <div class="screen-header-button close"></div>
                  <div class="screen-header-button maximize"></div>
                  <div class="screen-header-button minimize"></div>
                </div>
                <div class="screen-header-right">
                  <div class="screen-header-ellipsis"></div>
                  <div class="screen-header-ellipsis"></div>
                  <div class="screen-header-ellipsis"></div>
                </div>
              </div>
              <div class="screen-body">
                <div class="screen-body-item left2">
                  <div class="app-title">
                    <span>CONTACT</span>
                    <span>ME</span>
                  </div>
                  <div class="app-contact">
                    CONTACT INFO : +62 81 314 928 595
                  </div>
                </div>
                <form onSubmit={sendEmail} ref={form}>
                  <div class="screen-body-item">
                    <div class="app-form">
                      <div class="app-form-group">
                        <input
                          class="app-form-control"
                          placeholder="NAME"
                          value="Olimar Bueso"
                        />
                      </div>
                      <div class="app-form-group">
                        <input
                          class="app-form-control"
                          placeholder="FULL NAME"
                          name="name"
                        />
                      </div>
                      <div class="app-form-group">
                        <input
                          class="app-form-control"
                          placeholder="EMAIL"
                          name="email"
                        />
                      </div>
                      <div class="app-form-group message">
                        <input
                          class="app-form-control"
                          placeholder="MESSAGE"
                          name="message"
                        />
                      </div>
                      <div class="app-form-group buttons">
                        <button class="app-form-button">CANCEL</button>
                        <button class="app-form-button">SEND</button>
                        <input
                          class="app-form-button"
                          type="submit"
                          value="SEND"
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div> */}

        <div className="pt-5 pe-5 controller-div">
          <div className="test2">
            <div className="mt-4 p-4">
              <h1 className="Licorice">Contáctame</h1>
            </div>
            <form onSubmit={sendEmail} ref={form}>
            <div className="mt-3 d-grid p-4 nunito">
              <label className="">FULL NAME</label>
              <input className="input-contactme" name="name"></input>
            </div>
            <div className="mt-3 d-grid p-4 nunito">
              <label className="">EMAIl</label>
              <input className="input-contactme" name="email" ></input>
            </div>
            <div className="d-grid mt-3 p-4 nunito">
              <label>MENSAGE</label>
              <textarea className="textarea-contactme" name="message"></textarea>
            </div>

            <div className="mt-1 p-3">
              <button className="button-31 nunito" 
              type="submit"
              value="SEND">ENVIAR</button>
            </div>
            </form>
          </div>
          <div className="test1">
            <InlineWidget
              url={windowDimensions.width > 700 ? "https://calendly.com/psicologaolimar/" : "https://calendly.com/psicologaolimar/60min"}
              // styles={{
              //   height: "1000px",
              //   width: "650px",
              //   Zindex: "2",
              //   position: "absolute",
              //   marginLeft: "-80px",
              //   marginTop: "0px",
              //   marginBottom: "50px",
              //   BackgroundColor: "black",
              // }}
              className="calendly"
            />
          </div>
        </div>
      </div>

      <QuoteParallaxContactMeTwo />

      <div className="bg-div-two">
        <div className="second-div-controller">
          <div className="">
            <img
              src={foto8}
              className="div1"
            ></img>
            <br />
            <br />
          </div>
          <div className="div2 p-5 ">
            <h2 className="mt-2 nunito">LLENAR FORMULARIO AQUI</h2>
            {/* <h5 className="mt-5 normal">
              Formulario adultos:{" "}
              <span>
                <a
                  href=" https://docs.google.com/forms/d/e/1FAIpQLSdDXMGJ6Z3TcGl7S_ayBdyEh9yxHV8XhXIeRpSMeLO1dkl8HQ/viewform"
                  target="_blank"
                  className="normal"
                >
                  Aqui
                </a>
              </span>
            </h5> */}
            <div className="mt-3">
            <a
              href=" https://docs.google.com/forms/d/e/1FAIpQLSdDXMGJ6Z3TcGl7S_ayBdyEh9yxHV8XhXIeRpSMeLO1dkl8HQ/viewform"
              target="_blank"
              className="nunito button-30 text-color"
            >
              Adultos
            </a>
            </div>

            <div className="mt-4">
            <a href="https://forms.gle/EDZQ9zccCtWadAZi8" target="_blank" className="nunito button-30 text-color">
             Infantojuvenil
            </a>
            </div>

            {/* <h5 className="mt-5 normal">
              Formulario Psicológica Infantojuvenil:{" "}
              <span>
                <a href="https://forms.gle/EDZQ9zccCtWadAZi8" target="_blank">
                  Aqui
                </a>
              </span>
            </h5> */}
          </div>
        </div>
      </div>

      <div className="App"></div>
      <PopupWidget
        url="https://calendly.com/psicologaolimar/30min"
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
        text="Click here to schedule!"
        textColor="#ffffff"
        color="#18B7BE"
      />
      {/* to here  */}
      
    </div>
    <Footer/>
    </>
  );
};
