import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Granim from "granim";
// import "../styles/index.css"
import { Parallax } from 'react-parallax';
import foto9 from "../img/Plantascrecen (1).jpg"

let image = "https://images.pexels.com/photos/3184419/pexels-photo-3184419.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"

export const ParallaxColaboraciones = () => {
  useEffect(() => {

  }, []);
  return (
    <>
      <Parallax blur={0} bgImage={foto9} bgImageAlt="the cat" strength={500} className="parallax-quote align-items-center d-flex justify-content-center">
       <div className="d-grid quote-parralax-div title-parralax-div-2">
       <h4 className="quote-text nunito text-center text-size-co">Riega eso en ti que deseas que <strong className="Licorice">CREZCA</strong></h4>
       <h1 className="text-center Licorice">Olimar Bueso</h1>
       </div>
    </Parallax>
    </>
  );
};
