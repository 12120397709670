import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

export const Pallets = () => {
  useEffect(() => {

  });
  return (
    <>
    {/* <div className="d-flex">
        <h1>Palleta de colores #1</h1>
        <div className="color1"></div>
        <div className="color2"></div>
        <div className="color3"></div>
        <div className="color4"></div>
    </div>

    <div className="d-flex mt-5">
        <h1>Palleta de colores #2</h1>
        <div className="color17"></div>
        <div className="color18"></div>
    </div> */}
     

     <div>
     <h1>FONT 1</h1>
        <h1 className="promp">Lorem Ipsum</h1>
        <p className="promp">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </p>
     </div>


     <div>
     <h1>FONT 2</h1>
        <h1 className="flower">Lorem Ipsum</h1>
        <h4 className="flower">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </h4>
     </div>

     <div>
     <h1>FONT 3</h1>
        <h1 className="nunito">Lorem Ipsum</h1>
        <h4 className="nunito">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </h4>
     </div>
     <div>
     <h1>FONT 4</h1>
        <h1 className="Licorice">Lorem Ipsum</h1>
        <h4 className="Licorice">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </h4>
     </div>
     <div>
     <h1>FONT 5</h1>
        <h1 className="Cedarville">Lorem Ipsum</h1>
        <h4 className="Cedarville">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </h4>
     </div>
     <div>
     <h1>FONT 6</h1>
        <h1 className="oswald">Lorem Ipsum</h1>
        <h4 className="oswald">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. </h4>
     </div>

     
    </>
  );
};
