import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Granim from "granim";
// import "../styles/index.css"
import { Parallax } from 'react-parallax';

let image = "https://images.pexels.com/photos/1263986/pexels-photo-1263986.jpeg?cs=srgb&dl=pexels-andre-furtado-1263986.jpg&fm=jpg"

export const HomeTestTwo = () => {
  useEffect(() => {

  }, []);
  return (
    <>
    <div className=" no-mobile">
      <Parallax blur={1} bgImage={image} bgImageAlt="the cat" strength={200} className="parallax-bg align-items-center d-flex justify-content-center">
       <div className="title-parralax-div  no-mobile">
       <h4 className="text-center nunito">Tu bienestar interior ¡es salud!</h4>
       <h1 className="text-center Licorice">Olimar Bueso</h1>
       </div>
    </Parallax>
    </div>

    <div className="position-relative no-pc">
      <img src={image} className="parallax-bg "></img>
      <div className="position-absolute top-50 start-50 translate-middle title-parralax-div">
      <h5 className="text-center nunito">Tu bienestar interior <br/> ¡es salud!</h5>
      <h1 className="text-center Licorice">Olimar Bueso</h1>
       </div>
    </div>
    </>
  );
};
