import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Granim from "granim";
// import "../styles/index.css"
import { Parallax } from 'react-parallax';

let image = "https://images.pexels.com/photos/1108234/pexels-photo-1108234.jpeg?cs=srgb&dl=pexels-phil-kallahar-1108234.jpg&fm=jpg"

export const QuoteParallax = () => {
  useEffect(() => {

  }, []);
  return (
    <>
      <Parallax blur={1} bgImage={image} bgImageAlt="the cat" strength={300} className="parallax-quote align-items-center d-flex justify-content-center">
       <div className="quote-parralax-div ">
       <h4 className="quote-text nunito text-center"><i class="fas fa-quote-left"></i>De nuestras vulnerabilidades vienen nuestras fortalezas<i class="fas fa-quote-right"></i></h4>
       <p className="text-autor-black Licorice">-Sigmund Freud</p>
       </div>
    </Parallax>
    </>
  );
};
