import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import "../styles/services.css";
import { Footer } from "../components.js/footer";
import { useLocation } from "react-router-dom";
import { PopupWidget } from "react-calendly";
import foto4 from "../img/Foto4.jpg";
import foto1 from "../img/psicoterapia.jpeg";
import foto2 from "../img/gratitude.jpg";
import foto3 from "../img/Floral.png";

import {
  MDBcard1,
  MDBcard1Body,
  MDBcard1Title,
  MDBcard1Text,
  MDBcard1Image,
  MDBBtn,
  MDBRipple,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBContainer,
  MDBRow,
  MDBCol,
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBCardHeader,
  MDBCardFooter,
} from "mdb-react-ui-kit";

export const Services = () => {
  const [basicModal, setBasicModal] = useState(false);
  const [infoData, setInfoData] = useState({});
  const options = [
    {
      title: "Ansiedad y depresion",
      description:
        "Implica un proceso diagnostico y psicoterapia, encaminada a la autocomprensión, gestión de las emociones, hábitos de vida saludable y potenciar los recursos internos.",
      price: "55$ - 60Min",
    },
    {
      title: "Problemas de autoestima",
      description:
        "Aprender a amarte es el primer paso para sanar. La fórmula para dejar de hacerte daño es distinta en c/u. ¡Descubre la tuya!",
      price: "55$ - 60Min",
    },
    {
      title: "Fobias y Traumas",
      description:
        "A través de técnicas integrativas y el EMDR, (Eye Movement Desensibilization and Reprocessing) te ayudo a superar progresivamente su trauma. El EMDR presenta múltiples estudios de validación científica, usa la estimulación bilateral, mediante movimientos oculares, sonidos o tapping (golpecitos) por los que se estimula un hemisferio cerebral cada vez.",
      price: "75$ - 60Min",
    },
    {
      title: "Psiconeuroinmunoendocrinología",
      description:
        "Desde la perspectiva de la PNIE acompañamos niños y adultos a comprender la interacción de sus emociones con su mente y cuerpo, a comprender el mensaje subyacente, a gestionar emociones y estrés a través de psicoeducación.¡Conócete con cons-ciencia!",
      price: "55$ - 60Min",
    },
    {
      title: "Manejo de emociones",
      description:
        "Aprende a reconocer tus emociones, a conocer su origen y gestionarlas, desarrolla inteligencia emocional e inteligencia comunicacional.",
      price: "55$ - 60min",
    },
    {
      title: "Análisis de sueños.",
      description:
        "Debes traer previamente por escrito tu sueño, preferiblemente uno reciente.",
      price: "55$ - 60min",
    },
    {
      title: "Evaluación Pre quirúrgica",
      description:
        "¡Sana de forma integral! Nuestra valoración psicológica es muy completa, incluye informe con 7 dimensiones: afectivo, cognitivo, conducta alimentaria, autoestima, personalidad, riesgo psicológico y riesgo psicosomático (PNIE). Además tiene un enfoque terapéutico, por lo que incluye recomendaciones personalizadas.",
      price: "150$ - 120min",
    },
    {
      title: "Hambre emocional.",
      description:
        "Las Conductas Alimentarias de Riesgo (CAR) son manifestaciones similares a los trastornos de la conducta alimentaria, sin embargo, se presentan con menor frecuencia e intensidad y generalmente se desarrollan durante la adolescencia. Descubre el mensaje detrás del hambre emocional.",
      price: "55$ - 60min",
    },
    {
      title: "Terapia Infanto juvenil.",
      description:
        "Nuestro enfoque es familiar, implica diagnóstico, diseño de programa terapéutico personalizado, y se dan herramientas tanto para padres o cuidadores como para el niño, y en ciertos casos también a las escuelas y maestros.¡El trabajo en equipo conduce al éxito!",
      price: "75$ -60min",
    },
  ];
  const toggleShow = () => setBasicModal(!basicModal);
  const location = useLocation();
  useEffect(() => {
    if (location.pathname == "/services") {
    }
  }, []);

  const getWindowDimensions = () => {
    const { innerWidth: width } = window;
    return {
      width,
    };
  };

  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions(getWindowDimensions());
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  return (
    <>
      <div className="bg-one-services">
        <MDBRow around>
          <MDBCol className="d-block m-auto" sm="12" md="7" lg="7">
            <div className="p-5">
              <h1 className="nunito">
                <i class="fas fa-quote-left"></i> Así como el árbol pierde sus
                hojas y queda{" "}
                <span className="bg-text Licorice ps-2 pe-2">vulnerable</span>,
                así te podrías sentir… Pero lo que descubrirás es que tras ello
                viene un{" "}
                <span className="bg-text Licorice ps-2 pe-2">renacer</span>{" "}
                <i class="fas fa-quote-right"></i>
              </h1>
              <h2 className="Licorice mt-4">
                <strong>¡Te acompaño!</strong>
              </h2>
              <p className="nunito mt-4">
                Escucharte y comprenderte te permite trascender aquello que
                produce malestar.
              </p>
              <Link to="/contactMe">
                <button className="button-30 normal">CONTACTAME</button>
              </Link>
            </div>
          </MDBCol>
          <MDBCol sm="12" md="5" lg="5">
            <img
              className="image-services"
              src="https://images.pexels.com/photos/4672710/pexels-photo-4672710.jpeg?cs=srgb&dl=pexels-anna-shvets-4672710.jpg&fm=jpg"
            />
          </MDBCol>
        </MDBRow>
      </div>
      <div className="whatsAppflatin"> 
       <a href="https://wa.me/message/BO2SXTBCBZ5ZD1" target="_blank" className="whatsAppflatin-icon"><i class="fab fa-whatsapp color-whatsApp-floating mt-2 ms-1"></i></a> 
       </div>
      <div className="banner-two-service">
        <MDBRow className="d-flex">
          <MDBCol
            sm="12"
            md="5"
            lg="5"
            order={windowDimensions.width <= 760 ? "2" : "1"}
          >
            <img
              src={foto1}
              className="services-image-two"
            />
          </MDBCol>
          <MDBCol
            className="d-grid text-controller"
            sm="12"
            md="7"
            lg="7"
            order={windowDimensions.width <= 760 ? "1" : "2"}
          >
            <div className="d-flex">
              {/* <span className="question-mark ms-4">¿</span> */}
              <h1 className="porqueYo pt-3 Licorice separation-padding">
                {" "}
                Mereces atención profesional y personalizada
              </h1>
              {/* <span className="question-mark padding-mark">?</span> */}
            </div>
            <p className="nunito padding-controller-text">
              Olimar cuenta con casi 10 años de experiencia combinada en el
              ámbito clínico y educativo, en diferentes clínicas y centros
              educativos de Venezuela y Ecuador, realizando evaluaciones
              psicológicas y acompañando procesos terapéuticos de niños,
              adolescentes y adultos.
            </p>
            <p className="nunito padding-controller-text">
              Asi mismo, ha laborado en instituciones educativas como docente y
              psicóloga educativa, y he sido ponente en diversos talleres,
              conferencias, capacitaciones y congresos de forma presencial y
              virtual.
            </p>
            <p className="nunito padding-controller-text">
              “Mi pasión es ayudar a TRASCENDER los nudos que atan, y reconectar
              con tu interior”
            </p>
          </MDBCol>
        </MDBRow>
      </div>

      <div className="banner-two-service">
        <MDBRow className="d-flex">
          <MDBCol
            className="d-grid text-controller ps-5"
            sm="12"
            md="7"
            lg="7"
            order={windowDimensions.width <= 760 ? "1" : "2"}
          >
            <div className="d-flex">
              <h1 className={windowDimensions.width <= 760 ? "porqueYo pt-3 Licorice ps-0" : "porqueYo pt-3 Licorice ps-5"}>
                {" "}
                Psicoterapia Online
              </h1>
            </div>
            <p className={windowDimensions.width <= 760 ?"list-text nunito ps-0" :"list-text nunito ps-5" }>
              <i class="far fa-check-circle"></i> Desde cualquier lugar que estés
            </p>
            <p className={windowDimensions.width <= 760 ?"list-text nunito ps-0" :"list-text nunito ps-5" }>
              <i class="far fa-check-circle"></i> Comodidad de horarios
            </p>
            <p className={windowDimensions.width <= 760 ?"list-text nunito ps-0" :"list-text nunito ps-5" }>
              <i class="far fa-check-circle"></i> Muy útil cuando las personas (padres, pareja) están en
diferentes lugares.
            </p>
            <p className={windowDimensions.width <= 760 ?"list-text nunito ps-0" :"list-text nunito ps-5" }>
              <i class="far fa-check-circle"></i> Misma calidad que la presencial.
            </p>
            <p className={windowDimensions.width <= 760 ?"list-text nunito ps-0" :"list-text nunito ps-5" }>
              <i class="far fa-check-circle"></i> Ahorro de tiempo y gastos en movilización
            </p>
            <p className={windowDimensions.width <= 760 ?"list-text nunito ps-0" :"list-text nunito ps-5" }>
              <i class="far fa-check-circle"></i> En la comodidad de tu espacio íntimo.
            </p>
            {/* <ul >
              <li className="ps-4">
                Mejorar tu auto-amor, a crear una relación no-violenta con quien
                eres.
              </li>
              <li className="ps-4">
                Conectar con tu cuerpo ¿sientes que el estrés o emociones están
                afectando tu salud?
              </li>
              <li className="ps-4">Mejorar tu relación con tu cuerpo y la comida.</li>
              <li className="ps-4">Sanar heridas y superar traumas.</li>
            </ul> */}
          </MDBCol>
          <MDBCol
            sm="12"
            md="5"
            lg="5"
            order={windowDimensions.width <= 760 ? "1" : "2"}
          >
            <img
              src={foto2}
              // src={
              //   windowDimensions.width > 760
              //     ? "https://images.pexels.com/photos/5699456/pexels-photo-5699456.jpeg?cs=srgb&dl=pexels-alex-green-5699456.jpg&fm=jpg"
              //     : "https://images.pexels.com/photos/5699466/pexels-photo-5699466.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              // }
              className="services-image-two"
            />
          </MDBCol>
        </MDBRow>
      </div>

      <div className="banner-three-service">
        <MDBRow className="d-flex">
          <MDBCol className="d-grid text-controller-2 pe-4" sm="12" md="7" lg="7">
            <h1 className="terapyList Licorice">
              ¡Cursos, talleres y conferencias!
            </h1>
            <div>
              <div className="center-list">
                <p className=" list-text nunito">
                  <i class="far fa-check-circle"></i> Conócete con cons-ciencia
                </p>
                <p className=" list-text nunito">
                  <i class="far fa-check-circle"></i> Salud, estrés y Psiconeuroinmunología
                </p>
                <p className=" list-text nunito">
                  <i class="far fa-check-circle"></i> Traumas, fobias, ansiedad.
                </p>
                <p className=" list-text nunito">
                  <i class="far fa-check-circle"></i> Sanar heridas emocionales
                </p>
                <p className=" list-text nunito">
                  <i class="far fa-check-circle"></i> Alimentación emocional
                </p>
                <p className=" list-text nunito">
                  <i class="far fa-check-circle"></i> Como sanar la relación con
                  el cuerpo
                </p>
                <p className=" list-text nunito">
                  <i class="far fa-check-circle"></i> Como superar rupturas
                  amorosas
                </p>
                <p className=" list-text nunito">
                  <i class="far fa-check-circle"></i> Celos e infidelidad.
                </p>
                <p className=" list-text nunito">
                  <i class="far fa-check-circle"></i> Motivación, autoestima y
                  autoconfianza
                </p>
                <p className=" list-text nunito">
                  <i class="far fa-check-circle"></i> Salud emocional en niños y adolescentes
                </p>
              </div>
              <div>
                <p>Suscríbete con tu correo para recibir información.</p>
              </div>
            </div>
          </MDBCol>
          <MDBCol className="" sm="12" md="5" lg="5">
            {/* <img
              src={
                windowDimensions.width > 760
                  ? {foto4}
                  : {foto4}
              }
              className="services-image-three"
            /> */}
            <img src={foto3} className="services-image-three" />
          </MDBCol>
        </MDBRow>
      </div>

      <div className="containerServices">
        <div className="selec">
          <div className="row">
            <h1 className="terapyList Licorice text-center">Mis servicios</h1>
            <p className="text-center nunito">
              Para niños, adolescentes y adultos.
            </p>
          </div>
          <div className="row">
            <div
              className="column"
              onClick={() => {
                setInfoData(options[0]);
                toggleShow();
              }}
            >
              <div className="card-services">
                <div className="icon-wrapper">
                  {/* <i class="fas fa-hammer"></i> */}
                </div>
                <h3 className="Licorice">Ansiedad y Depresión</h3>
                <p className="nunito">
                  Si notas síntomas que persisten como: decaimiento, tristeza,
                  irritabilidad, corazón acelerado, intranquilidad, pérdida de
                  interés o placer.
                </p>
              </div>
            </div>
            <div
              class="column"
              onClick={() => {
                setInfoData(options[1]);
                toggleShow();
              }}
            >
              <div class="card-services">
                <div class="icon-wrapper">
                  {/* <i class="fas fa-brush"></i> */}
                </div>
                <h3 className="Licorice">Autoestima y amor propio</h3>
                <p className="nunito">
                  Para superar inseguridades, apegos, fortalecer tu confianza,
                  autoimagen.
                </p>
              </div>
            </div>
            <div
              class="column nunito"
              onClick={() => {
                setInfoData(options[2]);
                toggleShow();
              }}
            >
              <div class="card-services">
                <div class="icon-wrapper">
                  {/* <i class="fas fa-wrench"></i> */}
                  {/* <i class="fas fa-broom"></i> */}
                </div>
                <h3 className="Licorice">Fobias y Traumas,</h3>
                <p className="nunito">
                  Estrés postraumático (PTSD), heridas emocionales, duelos
                  (separación pareja, muertes, abusos psicológicos o físicos) u
                  otros eventos difíciles.
                </p>
              </div>
            </div>
            <div
              class="column"
              onClick={() => {
                setInfoData(options[3]);
                toggleShow();
              }}
            >
              <div class="card-services">
                <div class="icon-wrapper">
                  {/* <i class="fas fa-broom"></i> */}
                </div>
                <h3 className="Licorice">Psiconeuroinmunoendocrinología</h3>
                <p className="nunito">
                  ¿El médico te dijo que esos síntomas físicos o enfermedad es
                  por estrés? Los síntomas físicos pueden agravar los
                  emocionales y vice-versa.
                </p>
              </div>
            </div>
            <div
              class="column"
              onClick={() => {
                setInfoData(options[4]);
                toggleShow();
              }}
            >
              <div class="card-services">
                <div class="icon-wrapper">
                  {/* <i class="fas fa-broom"></i> */}
                </div>
                <h3 className="Licorice">Emociones y relaciones</h3>
                <p className="nunito">
                  Aprende a manejar la ira, impulsividad, a gestionar miedos,
                  comunicación asertiva, desarrolla habilidades sociales.
                </p>
              </div>
            </div>
            <div
              class="column"
              onClick={() => {
                setInfoData(options[5]);
                toggleShow();
              }}
            >
              <div class="card-services">
                <div class="icon-wrapper">
                  {/* <i class="fas fa-plug"></i> */}
                  {/* <i class="fas fa-broom"></i> */}
                </div>
                <h3 className="Licorice">Análisis de sueños</h3>
                <p className="nunito">
                  Los sueños traen mensajes del inconsciente. Es útil
                  interpretarlos cuando te deja un carga emocional intensa y/o
                  no puedes olvidarlo.
                </p>
              </div>
            </div>
            <div
              class="column"
              onClick={() => {
                setInfoData(options[6]);
                toggleShow();
              }}
            >
              <div class="card-services">
                <div class="icon-wrapper">
                  {/* <i class="fas fa-plug"></i> */}
                  {/* <i class="fas fa-broom"></i> */}
                </div>
                <h3 className="Licorice">Evaluación Pre quirúrgica</h3>
                <p className="nunito">
                  ¿Necesitas una valoración psicológica prequirúrgica,
                  bariátrica o balón intragástrico? Te acompañamos en un
                  tratamiento integral.
                </p>
              </div>
            </div>
            <div
              class="column"
              onClick={() => {
                setInfoData(options[7]);
                toggleShow();
              }}
            >
              <div class="card-services">
                <div class="icon-wrapper">
                  {/* <i class="fas fa-plug"></i> */}
                  {/* <i class="fas fa-broom"></i> */}
                </div>
                <h3 className="Licorice">Hambre emocional</h3>
                <p className="nunito">
                  Canaliza tu alimentación emocional, ingesta compulsiva,
                  atracones, restricción, CAR, mejora la relación con el cuerpo.
                </p>
              </div>
            </div>
            <div
              class="column"
              onClick={() => {
                setInfoData(options[8]);
                toggleShow();
              }}
            >
              <div class="card-services">
                <div class="icon-wrapper">
                  {/* <i class="fas fa-plug"></i> */}
                  {/* <i class="fas fa-broom"></i> */}
                </div>
                <h3 className="Licorice">Terapia Infanto juvenil</h3>
                <p className="nunito">
                  Enfocada en el manejo de emociones, ansiedad, traumas, fobias,
                  y habilidades sociales.
                </p>
              </div>
            </div>
          </div>
        </div>

        <MDBModal show={basicModal} setShow={setBasicModal} tabIndex="-1">
          <MDBModalDialog size="lg">
            <MDBModalContent>
              <MDBModalHeader className="bg-modal">
                <MDBModalTitle>{infoData.title}</MDBModalTitle>
                <MDBBtn
                  className="btn-close"
                  color="none"
                  onClick={toggleShow}
                ></MDBBtn>
              </MDBModalHeader>
              <MDBModalBody>
                <div>
                  <p>{infoData.description}</p>
                  <h3 className="text-black">{infoData.price}</h3>
                </div>
              </MDBModalBody>

              {/* <MDBModalFooter>
                <MDBBtn color="secondary" onClick={toggleShow}>
                  Close
                </MDBBtn>
                <MDBBtn>Save changes</MDBBtn>
              </MDBModalFooter> */}
            </MDBModalContent>
          </MDBModalDialog>
        </MDBModal>
      </div>

      <div className="reviews">
        <h1 className=" Licorice text-center p-2 review-text">Reviews</h1>
        <MDBRow className="">
          <MDBCol
            className="d-flex justify-content-center"
            sm="12"
            md="4"
            lg="4"
          >
            <MDBCard alignment="center" className="card-reviews">
              <MDBCardHeader>Reviews</MDBCardHeader>
              <MDBCardBody>
                <MDBCardTitle className="nunito">Carmen</MDBCardTitle>
                <MDBCardText className="nunito text-size-controller">
                  <i class="fas fa-quote-left small"></i> “Olimar es excelente
                  como persona y como profesional. Nos dio confianza en la forma
                  como manejó las sesiones. Totalmente recomendable su apoyo”{" "}
                  <i class="fas fa-quote-right small"></i>
                </MDBCardText>
                <div className="mt-5">
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                </div>
              </MDBCardBody>
              <MDBCardFooter className="text-muted">Ecuador</MDBCardFooter>
            </MDBCard>
          </MDBCol>
          <MDBCol
            className="d-flex justify-content-center "
            sm="12"
            md="4"
            lg="4"
          >
            <MDBCard alignment="center" className="card-reviews">
              <MDBCardHeader>Review</MDBCardHeader>
              <MDBCardBody>
                <MDBCardTitle className="nunito">Marcela</MDBCardTitle>
                <MDBCardText className="nunito text-size-controller">
                  <i class="fas fa-quote-left small"></i> “Gracias a Olimar he
                  aprendido a conocerme y aceptarme como soy. Empecé por un tema
                  particular y luego entendí todo el nudo de cosas (varios
                  patrones inconscientes!) que tenía dentro por trabajar y
                  sanar, ha sido maravilloso! Las palabras quedan cortas para el
                  agradecimiento que siento por Olimar”{" "}
                  <i class="fas fa-quote-right small"></i>
                </MDBCardText>
                <div>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                </div>
              </MDBCardBody>
              <MDBCardFooter className="text-muted">Ecuador</MDBCardFooter>
            </MDBCard>
          </MDBCol>
          <MDBCol
            className="d-flex justify-content-center"
            sm="12"
            md="4"
            lg="4"
          >
            <MDBCard alignment="center" className="card-reviews">
              <MDBCardHeader>Review</MDBCardHeader>
              <MDBCardBody>
                <MDBCardTitle className="nunito">Nadia</MDBCardTitle>
                <MDBCardText className="nunito text-size-controller">
                  <i class="fas fa-quote-left small"></i>“Nos ayudó mucho a
                  superar la ansiedad y fobia que tenía mi hija de hace meses en
                  pocas sesiones! Me ayudaron sus estrategias familiares,
                  agradezco mucho su apoyo, mi hija ha vuelto a ser quien era
                  antes.”<i class="fas fa-quote-right small"></i>
                </MDBCardText>
                <div className="mt-5">
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                  <i class="fas fa-star"></i>
                </div>
              </MDBCardBody>
              <MDBCardFooter className="text-muted">Canada</MDBCardFooter>
            </MDBCard>
          </MDBCol>
        </MDBRow>
      </div>
      <PopupWidget
        url="https://calendly.com/psicologaolimar/30min"
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
        text="Click here to schedule!"
        textColor="#ffffff"
        color="#18B7BE"
      />

      <Footer />
    </>
  );
};
