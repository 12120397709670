import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {
  MDBContainer,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBIcon,
  MDBNavbarNav,
  MDBNavbarItem,
  MDBNavbarLink,
  MDBBtn,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBCollapse,
} from 'mdb-react-ui-kit';
import logo from "../img/newlogo2.JPG";

export const Navbar = () => {
  // const [showNavText, setShowNavText] = useState(false);
  // const [navBg, setNavBg] = useState("#9FCECE");
  const [showNavSecond, setShowNavSecond] = useState(false);
  const [showBasic, setShowBasic] = useState(false);
  const location = useLocation();
  // useEffect(() => {
  //   if (location.pathname == "/services") {
  //     // Change background color
  //     setNavBg("#9FCECE");
  //   }
  //   if (location.pathname == "/") {
  //     setNavBg("#9FCECE");
  //   }
  //   if (location.pathname == "/contactMe") {
  //     setNavBg("#809bce");
  //   }
  //   setShowBasic(!showBasic)
  // });
  return (
    <>
      <MDBNavbar expand='lg' style={{ backgroundColor: "#9FCECE" }} className="sticky-top">
      <MDBContainer fluid className="link-control">
        <Link to="/">
        <MDBNavbarBrand href='#'>
        <img src={logo} className="logo ms-4" />
        </MDBNavbarBrand>
        </Link>

        <MDBNavbarToggler
          aria-controls='navbarSupportedContent'
          aria-expanded='false'
          aria-label='Toggle navigation'
          onClick={() => setShowBasic(!showBasic)}
        >
          <MDBIcon icon='bars' fas color="dark" />
        </MDBNavbarToggler>

        <MDBCollapse navbar show={showBasic} className="controller-links">
          <MDBNavbarNav className=' mb-2 mb-lg-0'>  
          </MDBNavbarNav>

          <MDBNavbarNav className="">
              <MDBNavbarLink href="#" className="navbar-mobile" onClick={() => setShowBasic(!showBasic)}>
                <Link to="/">
                  <h6 className="p-controller">INICIO</h6>
                </Link>
              </MDBNavbarLink>
              <MDBNavbarLink href="#" className="navbar-mobile" onClick={() => setShowBasic(!showBasic)}>
                <Link to="/services">
                  <h6 className="p-controller">SERVICIOS</h6>
                </Link>
              </MDBNavbarLink>
              <MDBNavbarLink href="#" className="navbar-mobile" onClick={() => setShowBasic(!showBasic)}>
                <Link to="/aboutUs">
                  <h6 className="p-controller">COLABORACIONES</h6>
                </Link>
              </MDBNavbarLink>
              <MDBNavbarLink href="#" className="navbar-mobile" onClick={() => setShowBasic(!showBasic)}>
                <Link to="/contactMe">
                  <h6 className="p-controller">CITAS</h6>
                </Link>
              </MDBNavbarLink>
            </MDBNavbarNav>
        </MDBCollapse>
      </MDBContainer>
    </MDBNavbar>
    </>
  );
};
