import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Granim from "granim";
// import "../styles/index.css"
import foto8 from "../img/image_6487327.JPG";
import { Parallax } from 'react-parallax';



export const QuoteParallaxContactMe = () => {
  useEffect(() => {

  }, []);
  return (
    <>
      <Parallax blur={1} bgImage={foto8} bgImageAlt="the cat" strength={500} className="parallax-quote align-items-center d-flex justify-content-center test-image">
       <div className="quote-parralax-div p-5">
       <h4 className="quote-text-larger nunito text-center"><i class="fas fa-quote-left"></i>A veces necesitas caos en tu alma, para hacer nacer una estrella.<i class="fas fa-quote-right"></i></h4>
       <h6 className="quote-text-new nunito text-center">¡Vamos de la mano!</h6>
       </div>
    </Parallax>
    </>
  );
};
