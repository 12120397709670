import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import { Footer } from "../components.js/footer";
import { HomeTwo } from "../components.js/newHomeTest";
import { HomeTestTwo } from "../components.js/newHomeTestTwo";
import { QuoteParallax } from "../components.js/quoteParallax";
import { QuoteParallaxTwo } from "../components.js/quoteParallaxTwo";
import foto2 from "../img/Foto2.JPEG";
import foto1 from "../img/Foto1.JPG";
import foto4 from "../img/Foto4.jpg";
import "../styles/home.css";
import { PopupWidget } from "react-calendly";
import {
  MDBCard,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBInputGroup,
  MDBRow,
  MDBCol,
} from "mdb-react-ui-kit";

export const Home = () => {
  const toastStyles = {
    position: "fixed",
    bottom: "3%",
    // right: "0%",
    zIndex: "2",
    margin: "15px",
  };

  //both hooks are used with the toast element.
  const [scrollPosition, setScrollPosition] = useState(0);
  const [didUserScrollDown, setDidUserScrollDown] = useState(false);
  //function below constantly updates scrollPosition with the pageYOffset. Starts at 0, and increases as
  //the user scrolls down
  const handleScroll = () => {
    const position = window.pageYOffset;
    setScrollPosition(position);
    //As soon as the user moves and the position is no longer 0, the second hook is set to true which
    //triggers the toast
    position !== 0 && setDidUserScrollDown(true);
  };

  //adds the event listener on initial render and cleans it up when finished.
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  console.log(window.innerWidth);

  return (
    <div className="containerHome">
      {/* <HomeTwo/> */}
      <HomeTestTwo className="" />
      <MDBRow className="controller-component">
        <MDBCol
          className="d-flex justify-content-center ps-5 padding-separation"
          sm="12"
          md="6"
          lg="6"
        >
          <div>
            <h3 className="mt-4 text-1">
              <span className="nunito">
                Hola soy <br className="no-pc" />
              </span>{" "}
              <span className="olimarName Licorice"> Olimar </span>
              <span className="olimarName-reverse Licorice">Bueso </span>
            </h3>
            <h3 className="mt-3 text-2 nunito">
              <span className="span2 ps-2 me-2 Licorice">Estoy aca </span> para
              acompañarte a desenerredar los nudos mentales que te atan, y
              puedas <span className="span2 Licorice">Ser y Trascender.</span>
            </h3>
            {/* <p className="mt-3 text-3 normal">
              {" "}
              a desenerredar los nudos mentales que te atan, y puedas <br />{" "}
              <span className="span2">Ser y Trascender.</span>
            </p> */}
            <div className="d-flex">
              <Link to="/contactMe">
                <button className="mt-4 me-3 button-30 nunito">CITAS</button>
              </Link>
              <Link to="/aboutUs">
                <button className="mt-4 button-30 nunito">SERVICIOS</button>
              </Link>
            </div>
          </div>
        </MDBCol>
        <MDBCol className="d-flex justify-content-center" sm="12" md="6" lg="6">
          <div className="shape">
            <img className="image-home-one" src={foto2}></img>
          </div>
        </MDBCol>
      </MDBRow>
      <QuoteParallaxTwo />
      <div>
        <section>
          <div className="conta">
            <div className="left"></div>
            <div className="right">
              <div className="content">
                <h1 className="Licorice">
                ¿ Quien es{" "}
                  <span className="bg-text-name Licorice">Olimar Bueso</span>{" "}
                  ?
                </h1>
                <p className="nunito">
                  Me considero un ser humano en constante aprendizaje,
                  apasionada de las ciencias humanas, de Venezuela y con raíces
                  migrantes, la resiliencia forma parte de mi orientación.
                  <br />
                  <br />
                  ¿Mi profesión? Psicóloga con enfoque clínico, psicoterapeuta
                  integrativa fenomenológica, especializada en reprogramación de
                  traumas (EMDR) certificada por EMDR Institute USA y EMDR
                  Iberoamérica, así como en Psiconeuroinmunoendocrinologia
                  (PNIE) por el ICH Uruguay, siendo éstos mis enfoques
                  principales en terapia. En mi caminar, realicé dos maestrías,
                  una en psicología Infanto Juvenil y otra en Gerencia Avanzada
                  en Educación.
                  <br />
                  <br />
                  También, me he nutrido de diversas formaciones en PNL,
                  Gestalt, terapia sistémica familiar, análisis de sueños,
                  psicología transpersonal, salud cuántica, hipnoterapia,
                  herramientas terapéuticas para el hambre emocional, entre
                  otros.
                </p>
                <Link to="/services">
                  <button className="mt-4 button-31">CONOCE MAS</button>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </div>
      <QuoteParallax />
      <div className="homepage-banner-2 py-5">
        <h1 className="banner-2-h1  Licorice px-1 text-center text-black mb-3">
          ¿Cómo puedo apoyarte?
        </h1>
        {/* new idea */}
        <div className="mt-4">
          <MDBRow className="d-flex justify-content-evenly normal">
            <MDBCol
              className="mt-3 d-flex justify-content-center flip-card ms-2"
              sm={window.innerWidth < 1171 && "12"}
            >
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <h1 className="title-size nunito">
                    Evaluación <br /> Psicológica
                  </h1>
                </div>
                <div className="flip-card-back">
                  <p className="px-5 text-white text-size-home nunito">
                    Conócete: tu personalidad, tus creencias disfuncionales,
                    cómo manejas tus emociones, tu comportamiento.
                  </p>
                </div>
              </div>
            </MDBCol>

            <MDBCol
              className="mt-3 d-flex justify-content-center flip-card ms-2 "
              sm={window.innerWidth < 1171 && "12"}
            >
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <h1 className="title-size nunito">
                    Psicoterapia
                    <br /> individual
                  </h1>
                </div>
                <div className="flip-card-back">
                  <p className="px-5 text-white text-size-home nunito">
                    Sana heridas, aprende a amarte, mejora relaciones, obtén
                    herramientas para una vida más plena.
                  </p>
                </div>
              </div>
            </MDBCol>

            <MDBCol
              className="mt-3 d-flex justify-content-center flip-card ms-2 margin-top-controller"
              sm={window.innerWidth < 1171 && "12"}
            >
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <h1 className="title-size nunito">
                    Psicoterapia <br /> Infanto Juvenil
                  </h1>
                </div>
                <div className="flip-card-back">
                  <p className="px-5 text-white text-size-home nunito">
                    Comprende raíces de su conducta, obtén un plan de
                    intervención personalizado.
                  </p>
                </div>
              </div>
            </MDBCol>

            <MDBCol
              className="mt-3 d-flex justify-content-center flip-card ms-2 margin-top-controller"
              sm={window.innerWidth < 1171 && "12"}
            >
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <h1 className="title-size nunito">
                    Cursos, <br /> talleres
                  </h1>
                </div>
                <div className="flip-card-back">
                  <p className="px-5 text-white text-size-home nunito">
                    Adquiere conocimiento y herramientas para tu crecimiento
                    personal en vivo ó en tus horarios.
                  </p>
                </div>
              </div>
            </MDBCol>

            <MDBCol
              className="mt-3 d-flex justify-content-center flip-card ms-2 margin-top-controller"
              sm={window.innerWidth < 1171 && "12"}
            >
              <div className="flip-card-inner">
                <div className="flip-card-front">
                  <h1 className="title-size nunito">
                    Ponencias <br />
                    Colaboraciones
                  </h1>
                </div>
                <div className="flip-card-back">
                  <p className="px-5 text-white text-size-home nunito">
                    ¿Necesitas apoyo en tu organización? Escríbenos y con gusto
                    podemos ayudarte.
                  </p>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </div>

        <h3 className="pt-4 m-0 text-center fs-32 text-al mobile-view-hidden text-black mt-5 nunito">
          Enamórate del proceso y los resultados llegarán.
        </h3>
      </div>
      <div
        className={didUserScrollDown ? "toast show" : "toast hide"}
        style={toastStyles}
      >
        <div className="toast-header" style={{ backgroundColor: "#18B7BE" }}>
          <strong className="me-auto nunito" style={{ color: "black" }}>
            Hola Soy Olimar
          </strong>
          <button
            type="button"
            className="btn-close"
            data-bs-dismiss="toast"
          ></button>
        </div>
        <div
          className="toast-body d-flex"
          style={{ backgroundColor: "ghostwhite" }}
        >
          <div>
            <img src={foto1} className="toaster-image"></img>
          </div>
          <div className="pt-4 ms-3">
            <p className="nunito">
              ¿En qué te gustaría que te acompañe?
              {/* <Link to="/contactMe" className=".active">
                CONTACTAME AQUI
              </Link> */}
              <a href="https://wa.me/message/BO2SXTBCBZ5ZD1" target="_blank" className="color-whatsApp">CONTACTAME AQUI</a> <i class="fab fa-whatsapp"></i>
            </p>
          </div>
        </div>
      </div>{" "}
      <PopupWidget
        url="https://calendly.com/psicologaolimar/30min"
        /*
         * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
         * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
         */
        rootElement={document.getElementById("root")}
        text="Click here to schedule!"
        textColor="#ffffff"
        color="#18B7BE"
      />
      <Footer />
    </div>
  );
};
