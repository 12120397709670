import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Granim from "granim";
// import "../styles/index.css"
import { Parallax } from 'react-parallax';

let image = "https://images.pexels.com/photos/853168/pexels-photo-853168.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"

export const QuoteParallaxTeam = () => {
  useEffect(() => {

  }, []);
  return (
    <>
      <Parallax blur={1} bgImage={image} bgImageAlt="the cat" strength={200} className="parallax-quote align-items-center d-flex justify-content-center">
       <div className="quote-parralax-div-team "><h4 className="quote-text-large nunito text-br text-center"><i class="fas fa-quote-left"></i> El trabajo en equipo es el secreto que hace que gente común consiga resultados poco comunes.<i class="fas fa-quote-right"></i></h4></div>
    </Parallax>
    </>
  );
};
