import React, { useState, useEffect, useContext } from "react";
import { Link } from "react-router-dom";
import Granim from "granim";
// import "../styles/index.css"
import { Parallax } from 'react-parallax';

let image = "https://images.pexels.com/photos/1070967/pexels-photo-1070967.jpeg?cs=srgb&dl=pexels-julia-avamotive-1070967.jpg&fm=jpg&_gl=1*r6bsa9*_ga*MTI2OTIzOTI3Ny4xNjYyNDIyNDAx*_ga_8JE65Q40S6*MTY2NjA0ODg5NS4xMi4xLjE2NjYwNTAxNTMuMC4wLjA."

export const QuoteParallaxContactMeTwo = () => {
  useEffect(() => {

  }, []);
  return (
    <>
      <Parallax blur={1} bgImage={image} bgImageAlt="the cat" strength={500} className="parallax-quote align-items-center d-flex justify-content-center">
       <div className="quote-parralax-div p-5 "><h4 className="quote-text nunito text-center text-white">¡FORMULARIOS MAS ABAJO!</h4></div>
    </Parallax>
    </>
  );
};
