
// import './App.css';
import { Footer } from './components.js/footer';
import { Navbar } from './components.js/navbar';
import { Home } from './pages/home';
import { AboutUs } from './pages/aboutUs';
import {Services} from './pages/services'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import {ContactMe} from './pages/contactMe'
import {Pallets} from "./pages/pallets"
// import "@fortawesome/fontawesome-free/css/all.min.css";
// import "bootstrap-css-only/css/bootstrap.min.css";
// import "mdbreact/dist/css/mdb.css";


function App() {
  return (
    <Router>
    {/* <div className=""> */}
    <Navbar/>
     <Routes>
      <Route exact path='/' element={<Home/>}></Route>
      <Route exact path='/aboutUs' element={<AboutUs/>}></Route>
      <Route exact path='/contactMe' element={<ContactMe/>}></Route>
      <Route exact path='/services' element={<Services/>}></Route>
      <Route exact path='/pallets' element={<Pallets/>}></Route>
     </Routes>
    {/* </div> */}
    </Router>
  );
}

export default App;
